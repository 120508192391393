<template>
  <v-row class="d-flex justify-center align-center">
    <v-col cols="12" md="6">
      <v-img src="/artwork/help.png"></v-img>
    </v-col>
    <v-col cols="12" md="6">
      <v-form ref="form">
        <v-select
          prepend-icon="mdi-frequently-asked-questions"
          v-model="reason"
          :items="items"
          :rules="[rules.required]"
          :error-messages="errors && errors.reason ? errors.reason : []"
          label="Choose a reason"
          required
        ></v-select>

        <v-text-field
          prepend-icon="mdi-account"
          :label="$lang('Name')"
          :hint="$lang('Your full name')"
          v-model="full_name"
          type="text"
          :rules="[rules.required]"
          :error-messages="errors && errors.full_name ? errors.full_name : []"
        ></v-text-field>

        <v-textarea
          counter
          label="Description in details"
          v-model="text"
          :rules="[rules.required]"
          :error-messages="errors && errors.text ? errors.text : []"
        ></v-textarea>

        <v-text-field
          prepend-icon="mdi-email"
          :label="$lang('AUTH:REGISTER:INPUT_TEXT_EMAIL-LABEL')"
          :hint="$lang('AUTH:REGISTER:INPUT_TEXT_EMAIL-HINT')"
          v-model="email"
          type="email"
          :rules="[rules.required, rules.email]"
          :error-messages="errors && errors.email ? errors.email : []"
        ></v-text-field>
        <v-text-field
          prepend-icon="mdi-phone"
          prefix="+91-"
          :label="$lang('MOBILENUMBER')"
          :hint="$lang('10 Digit mobile number without country code.')"
          v-model="mobile"
          type="number"
          :error-messages="errors && errors.mobile ? errors.mobile : []"
          :rules="[rules.max]"
        ></v-text-field>

        <CheckboxRefundAndTerms :ctx="this" />

        <v-btn block :disabled="!agree" color="success" @click="doSubmit">
          Submit
        </v-btn>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import { axios } from "@/plugins/axios";
import bus from "@/bus";
import CheckboxRefundAndTerms from "../modules/CheckboxRefundAndTerms.vue";

export default {
  name: "payment-help",
  components: {
    CheckboxRefundAndTerms,
  },

  data() {
    return {
      loading: false,
      text: null,
      reason: null,
      full_name: null,
      email: null,
      mobile: null,
      items: [
        "Payment deducted but coins not credited",
        "Can not purchase product",
        "Impersonation",
        "Copyright infringement",
        "Other",
      ],
      agree: false,
      rules: {
        required: (value) => !!value || this.$lang("Required"),
        min: (v) => {
          return (
            (v && v.length >= 6) ||
            this.$lang("AUTH:REGISTER:INPUT_TEXT_PASSWORD-HINT")
          );
        },
        max: (v) => {
          return (
            (v && [0, 10].includes(v.length)) || this.$lang("MobileNumberRule")
          );
        },
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      authUser: "auth/user",
    }),
    errors: {
      get() {
        return this.$store.getters.getFormErrors;
      },
      set(_) {},
    },
  },
  watch: {},
  methods: {
    validate() {
      this.$refs.form.validate();
    },

    doSubmit() {
      bus.$emit("showWait");

      return axios
        .post("ugcp/help/", {
          text: this.text,
          reason: this.reason,
          agree: this.agree,
          contact_user_id: this.authUser ? this.authUser.id : 0,
          contact_email: this.email,
          contact_mobile: this.mobile,
          contact_full_name: this.full_name,
        })
        .then((res) => {
          this.$router.replace({ name: "home" });
        })
        .finally(() => {
          bus.$emit("hideWait");
        });
    },
  },
  created() {},
};
</script>

