<template>
  <div class="d-flex flex-row">
    <v-checkbox
      v-model="ctx.agree"
      :rules="[(v) => !!v || 'You must agree to continue!']"
    >
      <template v-slot:label>
        <div>
          I agree to the
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <a
                target="_blank"
                href="https://vikarn.com/community-guidelines"
                @click.stop
                v-on="on"
                >Community Guidelines</a
              >
            </template>
            Vikarn's Community Guidelines </v-tooltip
          >,
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <a
                target="_blank"
                href="https://vikarn.com/privacy"
                @click.stop
                v-on="on"
                >Privacy Policy</a
              >
            </template>
            Vikarn's Privacy Policy</v-tooltip
          >,
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <a
                target="_blank"
                href="https://vikarn.com/refund-policy"
                @click.stop
                v-on="on"
                >refund policy</a
              >
            </template>
            Vikarn's refund policy
          </v-tooltip>

          and
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <a
                target="_blank"
                href="https://vikarn.com/terms"
                @click.stop
                v-on="on"
                >terms</a
              >
            </template>
            Vikarn's terms of service
          </v-tooltip>
          of service.
        </div>
      </template>
    </v-checkbox>
  </div>
</template>

<script>
export default {
  props: ["ctx"],
};
</script>

<style>
</style>