var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-row"},[_c('v-checkbox',{attrs:{"rules":[function (v) { return !!v || 'You must agree to continue!'; }]},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v(" I agree to the "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('a',_vm._g({attrs:{"target":"_blank","href":"https://vikarn.com/community-guidelines"},on:{"click":function($event){$event.stopPropagation();}}},on),[_vm._v("Community Guidelines")])]}}])},[_vm._v(" Vikarn's Community Guidelines ")]),_vm._v(", "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('a',_vm._g({attrs:{"target":"_blank","href":"https://vikarn.com/privacy"},on:{"click":function($event){$event.stopPropagation();}}},on),[_vm._v("Privacy Policy")])]}}])},[_vm._v(" Vikarn's Privacy Policy")]),_vm._v(", "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('a',_vm._g({attrs:{"target":"_blank","href":"https://vikarn.com/refund-policy"},on:{"click":function($event){$event.stopPropagation();}}},on),[_vm._v("refund policy")])]}}])},[_vm._v(" Vikarn's refund policy ")]),_vm._v(" and "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('a',_vm._g({attrs:{"target":"_blank","href":"https://vikarn.com/terms"},on:{"click":function($event){$event.stopPropagation();}}},on),[_vm._v("terms")])]}}])},[_vm._v(" Vikarn's terms of service ")]),_vm._v(" of service. ")],1)]},proxy:true}]),model:{value:(_vm.ctx.agree),callback:function ($$v) {_vm.$set(_vm.ctx, "agree", $$v)},expression:"ctx.agree"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }